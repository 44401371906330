<template>
  <main>
    <div class="banner-title mb-6">
      <span class="flex-shrink-0">PROMOTE PROJECTS&nbsp;</span>
      <div class="d-flex justify-center">
        <span class="flex-shrink-0">TO EARN&nbsp;</span>

        <VueSlickCarousel :arrows="false" :dots="false" vertical autoplay infinite class="highlight-block">
          <div>REWARDS</div>
          <div>TOKENS</div>
          <div>NFT</div>
          <div>XP</div>
          <div>ALLOWLIST</div>
          <div>AIRDROP</div>
        </VueSlickCarousel>
      </div>
    </div>

    <v-divider></v-divider>

    <div class="operation-section flex-all-center">
      <div class="filter-toggle flex-all-center mr-4" @click="showFilters = !showFilters">
        <img :src="filterOptions.image" class="mr-3" />

        <span class="text-16-semi-bold">{{ filterOptions.text }}</span>
      </div>
      
      <v-text-field
        outlined
        rounded
        hide-details
        v-model="search"
        dense
        color="#fff"
        class="search-field"
        placeholder="Search"
      ></v-text-field>
    </div>

    <div class="d-flex bottom warp-box">
      <div class="filter-box filter" :class="{ 'hide': !this.showFilters }">
        <v-list class="py-0" flat>
          <v-list-group color="white" :value="true">
            <template v-slot:activator>
              <v-list-item-title class="font-weight-bold">Space Category</v-list-item-title>
            </template>

            <v-list-item-group multiple v-model="selectedCategories">
              <v-list-item v-for="item of categories" :key="item" :value="item" :ripple="false">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox outlined :input-value="active"></v-checkbox>
                  </v-list-item-action>
  
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </div>
  
      <div class="items-wrapper flex-100 flex-column" :class="{ 'hide': this.showFilters }">
        <span class="d-block text-center" v-if="!filteredProjects.length">All items have been loaded :)</span>

        <div class="grid-container" :style="`--colNumText: ${columns};`">
          <v-card class="project-card" v-for="(project, index) in filteredProjects" :key="index">
            <router-link class="project-link" :to="'/campaign/' + project._id">
              <v-img
                :aspect-ratio="1 / 1"
                :src="project.logo || 'https://cdn-2.galxe.com/galaxy/images/aboardexchange/1668740795904521013.png?optimizer=image&width=400&quality=100'"
                lazy-src="https://cdn-2.galxe.com/galaxy/images/aboardexchange/1668740795904521013.png?optimizer=image&width=400&quality=100"
              ></v-img>
          
              <v-divider></v-divider>
      
              <div class="project-content">
                <div class="project-name">
                  <span>{{ project.name }}</span>
                  <check-icon></check-icon>
                </div>

                <div class="project-options">
                  <v-tooltip bottom v-for="(option, index) of getProjectOptions(project)" :key="index">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="option" v-bind="attrs" v-on="on">
                        <v-icon x-small>{{ option.icon }}</v-icon>
                        {{ option.value }}
                      </div>
                    </template>

                    <span>{{ option.tooltip }}</span>
                  </v-tooltip>
                </div>
              </div>
            </router-link>
          </v-card>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { mapGetters } from 'vuex'
import CheckIcon from '@/assets/images/svg/check.svg'
import { mdiFormatListBulletedType, mdiAccountOutline, mdiLightningBoltOutline } from '@mdi/js'
import { throws } from 'assert'

export default {
  components: {
    CheckIcon,
    VueSlickCarousel
  },
  data: () => ({
    projects: [],
    wallets: {},
    commissions: {},
    loading: false,
    showFilters: true,
    search: null,
    categories: ['NFT', 'DEFI', 'P2E', 'Metaverse', 'Other'],
    selectedCategories: [],
    icons: {
      mdiFormatListBulletedType,
      mdiAccountOutline,
      mdiLightningBoltOutline
    }
  }),
  mounted() {
    if (this.$vuetify.breakpoint.width < 850) {
      this.showFilters = false;
    }
  },
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    columns() {
      const columns = {
        'filters': {
          1154: 2,
          1263: 3,
          9999: 4
        },
        'default': {
          550: 1,
          750: 2,
          1000: 3,
          1263: 4,
          9999: 5
        }
      }

      const type = this.showFilters ? 'filters' : 'default';
      
      let result = 5;

      for (let breakpoint of Object.keys(columns[type])) {
        if (this.$vuetify.breakpoint.width < breakpoint) {
          result = columns[type][breakpoint];
          break;
        }
      }

      return result;
    },
    filterOptions() {
      return {
        image: this.showFilters ?
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKQSURBVHgBzVmBcdswDIR6HUAbRJ0g6QRVN8gG8QbxBlYnsDqB2gncDexO4HYCORMomQABomdEO45NUqSVv8PJlgnoDZAgQGUUCGYu5XIj8g3XHKJ4FNlB/otssizbUAAyn8FCSgnci8xBRon8A5EHfCeL7LVFXsdsRH4I2R3FhBITWXKPTqSGB131S5Ff0FU0IgXFgBi6h2GVCl4MtVXARguZ0RhYXluNIXbEbgGPKpbkC4R0DQNzSgR4U7H1cgAUNKS3lBj6DDxr7apgwpqcnPXMW6dw66TFwIouDJ1KJ6cUJm6rKYAmAhZjd3Q+Ije10fJTALA4uzehhvcmCe0hrJWd2zcbMC9oYlherOybo+ae6N7BaJScia20M19KuLSkAGAr5JMr0N/mwAkx7ygAorewyFUUEa8RwdJ2y+L7BpaxPXdgfy3yx2xrtYdijkXF+JczSgDui4mW3qyY8+S2FrkbSgQz9T7TUBm7YCtS4LPqKVnyxE4q6i8O45RT/ok+OJTgC1PH8d+p70EUO5GvmT9cvEfg9GgIXrlooNlRkr+pD7WutDtKg77RGpFmKivNLCgy7DQzH5Gok5F8zS48fqurkm51FlvnZH3E4Iz7giMWweYlSVs3Kn6vkr0wuK9NlUtj38xTbPgh4KEAKQ5/qKf2Ip/qi3ioZFc0EfhcX8TnWr+EsEI7OzewxsApGne3TIIsfumjj62Pkl33Rd/GrOeYfmYdtDitcDccsSWFA1ZeYT1hzOwSLSZyQYEAsQUPB6JxFiPvHzh28Gjpoa9765KHI+DaNaS+h+iFXCrqT/b1szlEV3mi/UP0K4wxh+j620+RWupK1xbDj+AB2VIuKtcgUtD+awhD/q9eQ19DPAOoxav7xcIwyQAAAABJRU5ErkJggg==' :
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEYSURBVHgB7ZbREYIwDIajEzgCbtJNYAPZADYBJ2EE3AA3wA1iKuXMCxCh1x6S7y5H7xrakJbkB1CUHYGIF7KCrMMvLVlFlkBMKICUrMdp7FwOMaCNbyyQhszYbLo5O67ZfAohsUfHMlfO+JUskxcIhbtflkrg2yx9iHfcT2AxAl/jfDvwxIktbhdNJh0JWMAdbQ/bedJ2Vzs4S9+Q3Cta9AWhYTXPCHzHI27BE5IM3t2zEPiOJeYBocChe4xlppjxK1iZSSAktGHGCnE1HrcL3rDygjG7SY7LrS6DmLiuUrPa+Kl5OHSRcN1DUfYOqvJeH5wq79XgUZW3WFFL8aS8f1fUUvBoyttnBlV5bwZVefsLUpW38je8AUdUw+GLzRNjAAAAAElFTkSuQmCC',
        text: this.showFilters ? 'Hide Filters' : 'Show Filters'
      }
    },
    filteredProjects() {
      let projects = [...this.projects];

      if (this.search) {
        projects = projects.filter(project => (project.name || '').toLowerCase().includes(this.search.toLowerCase()));
      }

      if (this.selectedCategories.length) {
        projects = projects.filter(project => this.selectedCategories.includes(project.type));
      }
      
      return projects;
    }
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      if (this.loading) return;

      this.loading = true;

      this.$http
        .get('/publisher/projects')
        .then(({ data }) => {
          this.projects = data.docs.map(it => ({ ...it.project, offers: it.count }));
          this.wallets = data.wallets;
          this.commissions = data.commissions;
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },

    getProjectOptions(project) {
      return [
        {
          icon: this.icons.mdiFormatListBulletedType,
          value: project.offers,
          tooltip: 'Quests'
        },
        {
          icon: this.icons.mdiAccountOutline,
          value: this.wallets[project._id] || 0,
          tooltip: 'Users'
        },
        {
          icon: this.icons.mdiLightningBoltOutline,
          value: this.commissions[project._id] || 0,
          tooltip: 'Actions'
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/layouts/styles/_variables';

.flex-100 {
  flex: 1 0 0;
}

.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-title {
  margin: auto;
  flex-wrap: wrap;
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  overflow: hidden;
  display: flex;
  color: #fff;
  justify-content: center;

  .highlight-block {
    width: 210px;
    text-align: left;
    color: var(--v-primary-base);
  }

  @media screen and (max-width: 768px) {
    & {
      width: 296px;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
    }

    .highlight-block {
      width: 130px;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(var(--colNumText), 1fr);
  grid-gap: 28px;

  .project-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all .2s cubic-bezier(.17, .67, .83, .67);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &:hover {
      transform: scale(1.05);
    }

    .project-link {
      text-decoration: none;
    }

    .v-image {
      flex: none;
    }

    .project-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0 0;
    }

    .project-options {
      display: flex;

      .option {
        border: 1px solid var(--v-secondary-base);
        margin-right: 6px;
        padding: 2px 8px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: var(--v-secondary-base);

        .v-icon {
          margin-right: 4px;
        }
      }
    }

    .project-name {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        font-size: 18px;
        line-height: 26px;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        line-clamp: 2;
        text-decoration: none;
        font-weight: 700;
        text-align: left;
        color: #fff;
      }
    }
  }
}

.operation-section {
  z-index: 3;
  padding: 20px 39px;
  background: var(--v-background-base);

  .search-field {
    ::v-deep .v-input__slot {
      min-height: 48px !important;
      background: map-deep-get($material-dark, 'cards');
    }

    ::v-deep input {
      color: #fff;
    }
  }
}

.filter-toggle {
  width: 224px;
  height: 48px;
  color: #fff;
  cursor: pointer;
  background: map-deep-get($material-dark, 'cards');
  border-radius: 100px;

  &:hover {
    border: 1px solid #fff;
  }

  img {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 600px) {
    & {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
    }

    img {
      margin-right: 0 !important;
    }

    span {
      display: none;
    }
  }
}

.filter {
  width: 280px;
  background: map-deep-get($material-dark, 'cards');
  align-self: self-start;
  overflow: auto;
  margin-right: 36px;
  transition: all .3s ease-in-out;
  margin-left: 0;
  opacity: 1;

  .v-list {
    background: var(--v-background-base);
  }

  &.hide{
    margin-left: -316px;
    opacity: 0;
  }
}

@media screen and (max-width: 850px) {
  .filter {
    width: 100%;
    margin-right: 0;

    &.hide{
      margin-left: -100%;
    }
  }

  .items-wrapper.hide {
    display: none;
  }
}

</style>
